<script lang="ts">
	import type { TWxIcons } from "@xbs/lib-kanban";

	export let name: TWxIcons;
	export let size = 20;
	export let spin = false;
	export let click = null;
	export let clickable = !!click;

</script>

<i
	class="icon wxi wxi-{name}"
	class:wxi-spin={spin}
	class:clickable
	style="font-size:{size}px;"
	on:click={click} />

<style>
	.icon {
		color: var(--wx-icon-color);

		display: flex;
		align-items: center;
	}
	.clickable {
		cursor: pointer;
	}

</style>
