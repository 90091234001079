<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";
	import { wx } from "@xbs/svelte-wx";
	import type { IColumn, TId } from "@xbs/lib-kanban";
	import { isSameId } from "@xbs/lib-kanban";
	import Icon from "./../lib/Icon.svelte";
	const { Dropdown, List } = wx;

	export let columns: IColumn[];
	export let edit = true;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");
	const dispatch = createEventDispatcher();
	let renameId = null;
	let columnLabel = null;

	function endRenaming() {
		if (renameId && columnLabel?.trim()) {
			dispatch("action", {
				action: "update-column",
				data: {
					id: renameId,
					label: columnLabel,
				},
			});
		}
		renameId = null;
		columnLabel = null;
	}

	function handleColumnRename(id: TId) {
		if (!edit) {
			return;
		}
		renameId = id;
	}
	function handleColumnInput(e: any) {
		columnLabel = e.target.value;
	}

	function handleInputKeypress(e: any) {
		if (e.charCode === 13) {
			endRenaming();
		}
	}

	function focus(node: HTMLElement) {
		node.focus();
	}

	let columnWithMenuId;
	function handleMenuClick(id: TId) {
		columnWithMenuId = id;
	}

	function handleMenuAction(action: number) {
		// [todo] rename actions after wx is fixed
		if (action === 1) {
			handleColumnRename(columnWithMenuId);
		}
		if (action === 2) {
			dispatch("action", {
				action: "delete-column",
				data: {
					id: columnWithMenuId,
				},
			});
		}
		columnWithMenuId = null;
	}

</script>

<div class="header">
	{#each columns as column (column.id)}
		<div class="column" on:dblclick={() => handleColumnRename(column.id)}>
			<div class="label">
				{#if isSameId(renameId, column.id)}
					<input
						type="text"
						class="input"
						value={column.label}
						on:input={handleColumnInput}
						on:keypress={handleInputKeypress}
						on:blur={endRenaming}
						use:focus />
				{:else}{column.label}{/if}

				{#if !isSameId(renameId, column.id) && edit}
					<div class="menu">
						<Icon
							name="dots-h"
							click={() => handleMenuClick(column.id)} />
						{#if isSameId(columnWithMenuId, column.id)}
							<Dropdown
								cancel={() => (columnWithMenuId = null)}
								width="auto">
								<List
									click={handleMenuAction}
									data={[{ icon: 'edit', label: _('Rename'), id: 1 }, { icon: 'delete', label: _('Delete'), id: 2 }]}
									let:obj>
									<div class="menu-item">
										<Icon name={obj.icon} />
										<span>{obj.label}</span>
									</div>
								</List>
							</Dropdown>
						{/if}
					</div>
				{/if}
			</div>
			{#if process.env.TRIALPACKAGE}
				<span
					class="mark"
					class:error={process.env.TRIALDATE < new Date()}>Trial</span>
			{/if}
		</div>
	{/each}
</div>

<style>
	.header {
		display: flex;
		flex-direction: row;
		position: relative;

		padding: var(--wx-default-padding) 0;

		height: var(--wx-kanban-header-height);
		min-height: var(--wx-kanban-header-height);
	}
	.column {
		min-width: var(--wx-kanban-column-width);
		width: var(--wx-kanban-column-width);
		margin-left: var(--wx-default-padding);

		background: var(--wx-card-background);
		border: var(--wx-default-border);
		border-radius: var(--wx-card-border-radius);
		padding: 0 var(--wx-default-padding);
		display: flex;
		align-items: center;
		position: relative;
	}
	.column:last-child {
		margin-right: var(--wx-default-padding);
	}

	.label {
		font-weight: 500;
		font-size: var(--wx-font-size-md);
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
	.label .input {
		font-weight: 500;
	}
	.input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;
		box-sizing: border-box;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	.input:focus {
		border: 1px solid var(--wx-input-focus-color);
	}

	.mark {
		position: absolute;
		right: 34px;
		top: 12px;
		transform: rotate(30deg);
		color: #ccc;
		font-weight: 400;
		text-transform: uppercase;
	}
	.mark.error {
		color: red;
	}

	.menu {
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		cursor: pointer;
	}
	.menu:hover {
		background: var(--wx-background);
	}
	.menu-item {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px;
		min-width: 120px;
	}
	.menu-item span {
		padding: 0 5px 0 15px;
	}

</style>
