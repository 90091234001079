<script lang="ts">
	import type { ICard, ICardShape } from "@xbs/lib-kanban";

	export let cardFields: ICard;
	export let cardShape: ICardShape;

	function getHeaderValues(cardFields: ICard, shape: ICardShape) {
		let values = [];

		if (shape?.priority.show) {
			const priorityValue = shape?.priority?.values?.find(
				priority => priority.id === cardFields.priority
			);

			if (priorityValue) {
				values.push({
					type: "priority",
					value: priorityValue.label,
					color: priorityValue.color,
				});
			}
		}

		const customFieldsConfig = shape.headerFields;
		if (customFieldsConfig) {
			const customFields = customFieldsConfig.reduce((total, field) => {
				if (cardFields[field.key]) {
					total.push({
						value: cardFields[field.key],
						label: field.label,
						css: field.css,
					});
				}
				return total;
			}, []);
			if (customFields) {
				values.push(...customFields);
			}
		}
		return values;
	}

	$: headerValues = getHeaderValues(cardFields, cardShape);

</script>

<div class="header">
	{#each headerValues as field}
		{#if field.value}
			{#if field.type === 'priority'}
				<div class="field {field.type}" style="color:{field.color};">
					<div
						class="priority-background"
						style="background:{field.color}" />
					<span class="priority-label"> {field.value} </span>
				</div>
			{:else}
				<div class="field {field.css}">
					{#if field?.label}
						<span class="label"> {field.label}: </span>
					{/if}
					<span class="value"> {field.value} </span>
				</div>
			{/if}
		{/if}
	{/each}
</div>

<style>
	.header {
		display: flex;
	}
	.header .field {
		margin-right: 10px;
		line-height: 25px;
		font-size: 14px;
	}
	.field {
		margin-bottom: var(--wx-kanban-card-field-padding);
	}

	.priority {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;

		padding: 0 10px;
		border-radius: 2px;

		position: relative;
		overflow: hidden;
	}
	.priority-background {
		opacity: 0.2;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.priority-label {
		text-transform: capitalize;
	}

</style>
