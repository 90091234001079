<script lang="ts">
	import {
		Toolbar,
		Search,
		Controls,
		DefaultTheme,
	} from "@xbs/svelte-kanban";
	import Template from "./Template.svelte";

	export let api;
	export let items = ["search", "controls"];

</script>

<DefaultTheme>
	<Toolbar>
		{#each items as item}
			{#if item === 'search'}
				<Search {api} />
			{:else if item === 'controls'}
				<Controls {api} />
			{:else if item}
				<Template template={item} />
			{/if}
		{/each}
	</Toolbar>
</DefaultTheme>
