<script lang="ts">
	import { writable } from "svelte/store";
	import { wx } from "@xbs/svelte-wx";
	import UploaderList from "./UploaderList.svelte";

	import type { Writable } from "svelte/store";
	import type { IAttachment, IEditorShape, ICard } from "@xbs/lib-kanban";

	const { Uploader } = wx;

	export let field: IEditorShape;
	export let values: Writable<ICard>;

	$: files = writable($values[field.key] || []) as Writable<IAttachment[]>;

	let ready = false;
	$: {
		if (ready) {
			$values[field.key] = $files;
		}
		ready = true;
	}

</script>

<UploaderList data={files} />
<Uploader
	bind:value={$values[field.key]}
	data={files}
	uploadURL={field.uploadURL}
	{...field.config} />
