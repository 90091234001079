<script lang="ts">
	import { getContext } from "svelte";
	import type { IApi } from "@xbs/lib-kanban";

	import Icon from "../Icon.svelte";
	import { uid } from "@xbs/lib-state";
	import type { Writable } from "svelte/store";

	export let api: IApi;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	function handleColumnAddClick() {
		api.exec("add-column", { id: uid(), label: _("Untitled") });
	}

	function handleRowAddClick() {
		api.exec("add-row", { id: uid(), label: _("Untitled") });
	}

	let rowKey: Writable<string>;
	$: {
		if (api) {
			rowKey = api.getStores().data.getReactive().rowKey;
		}
	}

</script>

<div class="controls">
	{#if $rowKey}
		<div
			class="control"
			on:click={handleRowAddClick}
			title={_('Add new row')}>
			<Icon name="table-row-plus-after" />
		</div>
	{/if}
	<div
		class="control"
		on:click={handleColumnAddClick}
		title={_('Add new column')}>
		<Icon name="table-column-plus-after" />
	</div>
</div>

<style>
	.controls {
		display: flex;
		justify-content: var(--wx-kanban-toolbar-controls-justify);
		align-items: center;
		flex: var(--wx-kanban-toolbar-controls-flex);
	}
	.control {
		display: flex;
		justify-content: center;
		align-items: center;

		cursor: pointer;
		padding: 4px;
		margin-left: 4px;
		transition: background-color 0.2s ease-out;
	}
	.control:hover {
		border-radius: var(--wx-input-border-radius);
		background-color: rgba(0, 0, 0, 0.07);
	}
	.control:active {
		background-color: rgba(0, 0, 0, 0.15);
	}

</style>
