<script lang="ts">
	import Footer from "./cardFields/Footer.svelte";
	import Header from "./cardFields/Header.svelte";
	import Icon from "../lib/Icon.svelte";

	import { wx } from "@xbs/svelte-wx";
	const { List, Dropdown } = wx;

	import { createEventDispatcher, getContext } from "svelte";
	import type { ICard, ICardShape } from "@xbs/lib-kanban";
	import Progress from "../lib/Progress.svelte";

	export let cardFields: ICard;
	export let cardShape: ICardShape;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	const dispatch = createEventDispatcher();

	$: menuOpen = false;

	function handleMenuClick(ev: MouseEvent) {
		ev.cancelBubble = true;
		menuOpen = !menuOpen;
	}
	function handleMenuAction(action: number) {
		// [todo] rename actions after wx is fixed
		if (action === 1) {
			dispatch("action", {
				action: "delete-card",
				data: cardFields,
			});
		}
	}

	$: coverAttachment = cardFields?.attached?.find(obj => obj.isCover);
	$: coverURL = coverAttachment
		? coverAttachment.coverURL || coverAttachment.url
		: null;

</script>

{#if cardShape?.color?.show}
	<div class="color rounded" style="background:{cardFields?.color}" />
{/if}
{#if cardShape?.cover?.show && coverURL}
	<div class="field image" class:rounded={!cardShape?.color?.show}>
		<img src={coverURL} alt="" />
	</div>
{/if}
<div class="content">
	<Header {cardFields} {cardShape} />
	<div class="body">
		<div class="field label">
			{#if cardShape?.label?.show && cardFields.label}
				<span> {cardFields.label} </span>
			{/if}
			{#if cardShape?.menu?.show}
				<div class="menu">
					<Icon name="dots-v" click={handleMenuClick} />
					{#if menuOpen}
						<Dropdown
							cancel={() => (menuOpen = false)}
							width="auto">
							<List
								click={handleMenuAction}
								data={[{ icon: 'delete', label: _('Delete'), id: 1 }]}
								let:obj>
								<div class="menu-item">
									<Icon name={obj.icon} />
									<span>{obj.label}</span>
								</div>
							</List>
						</Dropdown>
					{/if}
				</div>
			{/if}
		</div>
		{#if cardShape?.description?.show && cardFields.description}
			<div class="field description">{cardFields.description}</div>
		{/if}

		{#if cardShape?.progress?.show && cardFields.progress}
			<div class="field">
				<Progress max={100} value={cardFields.progress} />
			</div>
		{/if}
	</div>

	<Footer {cardFields} {cardShape} />
</div>

<style>
	.content {
		padding: 20px;

		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
	}

	.color {
		height: 5px;
		min-height: 5px;
		width: 100%;
	}
	.rounded {
		border-radius: 6px 6px 0 0;
	}
	.field {
		margin-bottom: var(--wx-kanban-card-field-padding);
	}

	.body .field:last-child {
		margin-bottom: 0;
	}
	.label {
		font-weight: 500;
		font-size: var(--wx-font-size-md);
		min-height: 1em;
		max-width: 100%;
		position: relative;
		padding-right: 10px;
		word-break: break-word;
	}
	.description {
		font-size: var(--wx-font-size-sm);
		/* [todo] uses non standart properties, try to implement another way */
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.image {
		overflow: hidden;
		width: 100%;
		height: 150px;
		margin-bottom: 0;
	}
	.image img {
		width: 100%;
		height: 100%;
	}
	.menu {
		position: absolute;
		top: 0;
		right: -5px;
	}
	.menu:hover {
		background: var(--wx-background);
	}
	.menu-item {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px;
		min-width: 120px;
	}
	.menu-item span {
		padding: 0 5px 0 15px;
	}

</style>
