<script lang="ts">
	import DefaulthTheme from "../themes/DefaultTheme.svelte";

	import Row from "./Row.svelte";
	import Column from "./Column.svelte";
	import Editor from "./Editor.svelte";
	import ColumnsHeader from "./ColumnsHeader.svelte";

	import type {
		ICard,
		ICardMeta,
		ICardShape,
		IColumn,
		IDispatchConfig,
		IEditorShape,
		IRow,
		TId,
		TMethodsConfig,
		TReadonlyConfig,
	} from "@xbs/lib-kanban";

	import type { IEventBus } from "@xbs/lib-state";
	import { EventBusRouter } from "@xbs/lib-state";

	import {
		createEventDispatcher,
		getContext,
		onDestroy,
		onMount,
		setContext,
	} from "svelte";
	import { writable } from "svelte/store";
	import type { Writable } from "svelte/store";

	import {
		connectStores,
		getApi,
		dndAction,
		selection,
		keyManager,
		defaultCardShape,
		StateStore,
		DataStore,
		getAreaId,
		locale,
		en,
		elementsIds,
	} from "@xbs/lib-kanban";
	import type { INormalizedCard } from "@xbs/lib-kanban/dist/types";

	export let columns: IColumn[];
	export let rows: IRow[] = null;
	export let cards: ICard[];
	export let cardShape: ICardShape = defaultCardShape;
	export let editorShape: IEditorShape[] = null;
	export let editorAutoSave = true;
	export let cardTemplate = null;
	export let readonly: TReadonlyConfig = false;
	export let columnKey = "column";
	export let rowKey = "";

	const i18nContext = getContext<any>("wx-i18n");
	if (!i18nContext) {
		setContext("wx-i18n", locale(en));
	} else if (!i18nContext?.data?.kanban) {
		i18nContext.extend(en);
	}

	const dispatch = createEventDispatcher();
	// [TODO] maybe, find more elegant way
	onMount(() => {
		if (!document.querySelector(".wx-portal")) {
			const portal = document.createElement("div");
			portal.classList.add("wx-portal");
			document.body.appendChild(portal);
		}
	});
	onDestroy(() => {
		document.querySelector(".wx-portal")?.remove();
	});

	const dataStore = new DataStore(x => writable(x));
	const stateStore = new StateStore(x => writable(x));

	let lastInRoute: IEventBus<TMethodsConfig> = new EventBusRouter(dispatch);

	connectStores(dataStore, stateStore, lastInRoute);
	export const api = getApi(dataStore, stateStore, lastInRoute);

	stateStore.init({
		dropAreaItemsCoords: null,
		dropAreasCoords: null,
		dragItemsCoords: null,

		dragItemId: null,
		before: null,
		overAreaId: null,
		overAreaMeta: null,

		selected: null,
		search: null,
	});
	dataStore.init({
		columnKey,
		rowKey,
		columns: columns || null,
		rows: rows || null,
		cards,
		cardsMap: {},
		cardsMeta: null,
		cardShape,
		editorShape,
		areasMeta: null,
	});

	const handleAction = ({ detail: { action, data } }) =>
		api.exec(action, data);

	let storeColumns: Writable<IColumn[]>,
		storeRowKey: Writable<string>,
		storeRows: Writable<IRow[]>,
		storeCards: Writable<INormalizedCard[]>,
		cardsMap: Writable<any>,
		storeCardShape: Writable<ICardShape>,
		storeEditorShape: Writable<IEditorShape[]>,
		storeCardsMeta: Writable<Record<string, ICardMeta>>;

	$: {
		dataStore.init({
			columnKey,
			rowKey,
			columns: columns || null,
			rows: rows || null,
			cards,
			cardsMap: {},
			cardsMeta: null,
			cardShape,
			editorShape,
			areasMeta: null,
		});
		const dataState = dataStore.getReactive();

		storeColumns = dataState.columns;
		storeRows = dataState.rows;
		storeRowKey = dataState.rowKey;
		storeCards = dataState.cards;
		storeCardShape = dataState.cardShape;
		storeEditorShape = dataState.editorShape;
		cardsMap = dataState.cardsMap;
		storeCardsMeta = dataState.cardsMeta;
	}

	const state = stateStore.getReactive();
	const movedCardId = state.dragItemId;
	const overCardId = state.before;
	const overColId = state.overAreaId;
	const dropAreasCoords = state.dropAreasCoords;
	const dragItemsCoords = state.dragItemsCoords;
	const selected = state.selected;

	$: movedCardCoords = $dragItemsCoords && $dragItemsCoords[$movedCardId];

	$: selectedCard = api.getCard($selected?.length === 1 && $selected[0]);

	let edit: boolean, add: boolean, select: boolean, dnd: boolean;

	$: {
		if (typeof readonly === "object") {
			edit = readonly?.edit;
			add = readonly?.add;

			select = readonly?.select;
			dnd = readonly?.dnd;
		} else {
			edit = add = select = dnd = readonly !== true;
		}

		if (!edit) {
			$storeCardShape.menu = $storeCardShape.menu || {};
			$storeCardShape.menu.show = false;
		}
	}

	function handleSelectAction(
		_name: string,
		data: { itemId?: TId; groupMode?: boolean }
	) {
		const { itemId, groupMode } = data;

		if (itemId) {
			api.exec("select-card", {
				id: itemId,
				groupMode,
			});
		} else if ($selected?.length) {
			api.exec("unselect-card", {
				id: null,
			});
		}
	}

	function handleDndActions(name: any, action: IDispatchConfig) {
		api.exec(name, action);
	}

	function handleHotkey(_name: any, data: IDispatchConfig<any>) {
		const { hotkey } = data;
		switch (hotkey) {
			case "delete":
				if ($selected?.length) {
					$selected.map(id => {
						api.exec("delete-card", {
							id,
						});
					});
				}
				break;
		}
	}

</script>

<DefaulthTheme>
	<div
		class="kanban"
		class:dragged={!!$movedCardId}
		use:selection={{ onAction: handleSelectAction, readonly: select === false }}
		use:dndAction={{ onAction: handleDndActions, api, readonly: dnd === false }}
		use:keyManager={{ onAction: handleHotkey, readonly: edit === false }}>
		<div class="content-wrapper" data-kanban-id={elementsIds.content}>
			<div class="content">
				{#if $storeColumns && $storeCards.length}
					<ColumnsHeader
						columns={$storeColumns}
						on:action={handleAction}
						{edit} />

					{#each $storeRows as row (row.id)}
						<Row
							{row}
							collapsable={!!$storeRowKey}
							on:action={handleAction}
							{edit}>
							{#each $storeColumns as column (column.id)}
								<Column
									on:action={handleAction}
									{column}
									{row}
									overCardId={$overCardId}
									overColId={$overColId}
									movedCardId={$movedCardId}
									{movedCardCoords}
									selected={$selected}
									dropAreasCoords={$dropAreasCoords}
									cards={$cardsMap[getAreaId(column.id, row.id)]}
									cardShape={$storeCardShape}
									{cardTemplate}
									cardsMeta={$storeCardsMeta}
									{add} />
							{/each}
						</Row>
					{/each}
				{/if}
			</div>
		</div>
		{#if edit}
			<Editor
				selectedCard={!$movedCardId && selectedCard}
				editorShape={$storeEditorShape}
				autoSave={editorAutoSave}
				on:action={handleAction} />
		{/if}
	</div>
</DefaulthTheme>

<style>
	.kanban * {
		box-sizing: border-box;
		font-family: var(--wx-font);
	}

	.kanban {
		display: flex;
		flex-direction: row;
		position: relative;

		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;

		background: var(--wx-background);
		color: var(--wx-font-color);
		overflow: hidden;
	}
	.dragged * {
		user-select: none;
	}
	.dragged .content-wrapper {
		overflow: hidden;
	}
	.content-wrapper {
		display: flex;
		flex: 1 1 auto;
		overflow: auto;
		position: relative;
	}
	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1 1 auto;
	}

	:global(.wx-portal .dragged-card) {
		font-family: Roboto, sans-serif;
		color: var(--wx-font-color);
		width: var(--wx-kanban-column-width);
		min-height: 70px;
		position: fixed;
		z-index: 999999;
		user-select: none;
		pointer-events: none;

		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
			0 15px 12px rgba(0, 0, 0, 0.22);
	}
	:global(.wx-portal .dragged-card:after) {
		content: var(--wx-dragged-cards-count);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: var(--wx-primary-color);
		color: #fff;
		position: absolute;
		border-radius: 50%;
		top: -10px;
		right: -10px;
		z-index: 1;
	}
	:global(.wx-ondrag) {
		overflow: hidden;
	}

</style>
