<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";

	import Card from "./Card.svelte";
	import CardContainer from "./CardContainer.svelte";
	import Icon from "../lib/Icon.svelte";

	import { getAreaId, includesId, isSameId } from "@xbs/lib-kanban";
	import type {
		IRect,
		ICard,
		ICardShape,
		IColumn,
		IRow,
		TId,
	} from "@xbs/lib-kanban";

	export let column: IColumn;
	export let row: IRow;
	export let cards: ICard[];
	export let overCardId: TId;
	export let movedCardId: TId;
	export let movedCardCoords: IRect;
	export let overColId: TId;
	export let selected: TId[];
	export let dropAreasCoords: IRect[];
	export let cardShape: ICardShape;
	export let cardTemplate = null;

	export let add = true;
	export let cardsMeta = null;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	const dispatch = createEventDispatcher();
	function handleAddCardClick(e: MouseEvent) {
		e.stopPropagation();
		dispatch("action", {
			action: "add-card",
			data: { columnId: column.id, rowId: row.id },
		});
	}

	$: isDragging = (id: TId) => {
		if (movedCardId) {
			return (
				isSameId(movedCardId, id) ||
				(selected?.length > 1 && includesId(selected, id))
			);
		}
		return false;
	};

	$: areaId = getAreaId(column.id, row.id);
	$: areaHeight =
		dropAreasCoords &&
		dropAreasCoords?.find(area => area.id === areaId)?.height;

	$: dragHeight = areaHeight ? `${areaHeight}px` : "auto";

	const borders = 2;
	$: movedCardHeight = movedCardCoords?.height - borders;

</script>

<div class="column" data-drop-area={areaId} style="min-height:{dragHeight}">
	{#if cards}
		{#each cards as card (card.id)}
			{#if isSameId(card.id, overCardId)}
				<div
					class="drop-area"
					style="min-height:{movedCardHeight}px;" />
			{/if}
			<CardContainer
				cardTemplate={cardTemplate || Card}
				cardFields={card}
				on:action
				dragging={isDragging(card.id)}
				selected={includesId(selected, card.id)}
				meta={cardsMeta && cardsMeta[card.id]}
				{cardShape} />
		{/each}
	{/if}
	{#if !overCardId && isSameId(overColId, areaId)}
		<div class="drop-area" style="min-height:{movedCardHeight}px;" />
	{/if}

	{#if add}
		<div class="add-card-btn" on:click={handleAddCardClick}>
			<Icon name="plus" />
			<span class="add-card-tip"> {_('Add new card')}... </span>
		</div>
	{/if}
</div>

<style>
	.add-card-tip {
		opacity: 0;
		transition: all 0.2s ease-in;
	}
	.column {
		min-width: var(--wx-kanban-column-width);
		width: var(--wx-kanban-column-width);

		margin-left: var(--wx-default-padding);
	}
	.column:last-child {
		margin-right: var(--wx-default-padding);
	}
	.column:hover .add-card-tip {
		opacity: 1;
	}
	.add-card-btn {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: var(--wx-font-size-sm);
		margin-top: var(--wx-default-padding);
		margin-bottom: var(--wx-default-padding);
		cursor: pointer;
	}
	.drop-area {
		width: 100%;
		border-radius: var(--wx-card-border-radius);
		margin-top: var(--wx-default-padding);
		border: dotted 1px var(--wx-primary-color);
	}

</style>
